import http from '../utils/Http';
import { eventTypes, getDefaultEndDate, getDefaultStartDate } from '../utils/Constants';

export default class EventService {
  static async getAlarm(eventId) {
    const res = await http.get(`/api/event/${eventId}`);
    const alarm = res.data;

    if (alarm.type !== eventTypes.ALARM) {
      throw new Error('Event is not type Alarm');
    }

    return alarm;
  }

  static async getReport(eventId) {
    const res = await http.get(`/api/event/${eventId}`);
    const report = res.data;

    if (report.type !== eventTypes.REPORT) {
      throw new Error('Event is not type Report');
    }

    return report;
  }

  static deleteEvent(eventId) {
    return http.delete(`/api/event/${eventId}`);
  }

  static getEvents(eventType) {
    return http.get('/api/event/', { params: { eventType } }).then((res) => res.data);
  }

  static getEventNames(eventType) {
    return http.get('/api/event/name', { params: { eventType } }).then((res) => res.data);
  }

  static createEvent(eventToCreate) {
    return http.post('/api/event', eventToCreate);
  }

  static updateEvent(eventToUpdate) {
    return http.put(`/api/event/${eventToUpdate.id}`, eventToUpdate);
  }

  static reactivateAlarm(alarmToReactivate) {
    return http.put(`/api/event/${alarmToReactivate.id}`, alarmToReactivate);
  }

  static getColumnDefinitions() {
    return http.get('/api/me/alarmColumns').then((res) => res.data);
  }

  static getMeTimezone() {
    return http.get('/api/me/timezone').then((res) => res.data);
  }

  static getMe() {
    return http.get('/api/me').then((res) => res.data);
  }

  static async getColumnSuggestions(column, query) {
    let requestQuery =
      `/api/assetobservation/suggest/?field=${column.originalDataField}` +
      `&substring=${encodeURIComponent(query)}&limit=500&useIndex=true&table=ALL_OBSERVATIONS_ELASTIC`;

    const fieldsNotToUseIndex = ['movementEvent', 'geofenceType'];
    if (fieldsNotToUseIndex.includes(column.originalDataField)) {
      requestQuery = requestQuery.replace('&useIndex=true&table=ALL_OBSERVATIONS_ELASTIC', '');
    }
    return http.get(requestQuery).then((res) => res.data);
  }

  static getAssets() {
    return http.get('/api/asset/*').then((res) => res.data);
  }

  static getCategoryGroups(categoryIndex) {
    return http.get(`/api/category/${categoryIndex}/group`).then((res) => res.data);
  }

  static getGeofenceAreas() {
    return http.get('/api/geofence/').then((res) => res.data);
  }

  static getCustomerUsers() {
    return http
      .get('/api/customeruser/?usersAbleToSubscribeToEvent=true')
      .then((res) => res.data.map((cm) => cm.customerContact));
  }

  static saveReportDesign(reportTemplate, isEdit) {
    if (isEdit) {
      return this.updateReportDesign(reportTemplate);
    } else {
      return this.createReportDesign(reportTemplate);
    }
  }

  static createReportDesign(reportTemplate) {
    return http.post('/api/report-design/', reportTemplate).then((res) => res.data);
  }

  static updateReportDesign(reportTemplate) {
    return http.put(`/api/report-design/${reportTemplate.id}`, reportTemplate).then((res) => res.data);
  }

  static deleteReportTemplate(reportTemplateId) {
    return http.delete(`/api/report-design/${reportTemplateId}`);
  }

  static getReportDesigns() {
    return http.get('/api/report-design/').then((res) => res.data);
  }

  static getReportDesignNames() {
    return http.get('/api/report-design/name/').then((res) => res.data);
  }

  static getReportTemplate(reportTemplateId) {
    return http.get(`/api/report-design/${reportTemplateId}`).then((res) => res.data);
  }

  static getReportCoverSheetByReportId(eventId) {
    return http.get(`/api/event/report-cover-sheet/${eventId}`).then((res) => res.data);
  }

  static getReportTemplateTilesByReportId(eventId) {
    return http.get(`/api/event/report-tiles/${eventId}`).then((res) => res.data);
  }

  static getReportTemplateTilesForReport(report) {
    return http.post(`/api/event/report-tiles`, report).then((res) => res.data);
  }

  static async getEventTemplates(eventType) {
    const eventTemplates = await http.get('/api/event-template/', { params: { eventType } }).then((res) => res.data);

    const defaultTemplateValues = {
      timezone: 'Etc/GMT',
      startDate: getDefaultStartDate(),
      endDate: getDefaultEndDate(),
      creationDate: null,
      editedDate: null,
    };

    return eventTemplates.map((eventTemplate) => ({
      ...eventTemplate,
      data: JSON.parse(eventTemplate.data),
      ...defaultTemplateValues,
    }));
  }

  static async getEventTemplate(eventType, templateId) {
    const eventTemplate = await http
      .get(`/api/event-template/${templateId}`, { params: { eventType } })
      .then((res) => res.data);

    const defaultTemplateValues = {
      timezone: 'Etc/GMT',
      startDate: getDefaultStartDate(),
      endDate: getDefaultEndDate(),
      creationDate: null,
      editedDate: null,
    };

    return {
      ...eventTemplate,
      data: JSON.parse(eventTemplate.data),
      ...defaultTemplateValues,
    };
  }
}
